import React from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'

import Container from '../../components/common/container'
import media from '../../components/css/media'
import Cta from '../../components/common/cta'
import useDeviceDetect from '../../hooks/useDeviceDetect'
// import { useLazyLoading } from '../../hooks/useLazyLoading'

const Banner = styled.section.attrs((props) => ({
  color: props.color || '#fff',
  bgcolor: props.bgcolor || '#fff',
  gutter: props.mobileGutter,
}))`
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgcolor};
  height: ${(props) => props.gutter - 50}px;
  position: relative;
  overflow: hidden;

  div {
    &.mobile {
      display: block;
    }
    &.desktop {
      display: none;
    }
  }

  img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  ${media.desktop`
    background-image: url("${(props) => props.bgimg}");
    height: calc((var(--vh, 1vh) * 100) - 60px);

    div{
      &.mobile {
        display: none;
      }
      &.desktop {
        display: block;
      }
    }
  `};
`

const HeaderContent = styled.div`
  position: absolute;
  top: 0;
  width: 92%;
  height: 100%;

  a {
    position: absolute;
    bottom: 5%;
    left: 45%;
    transform: translateX(-45%);
    width: 50%;

    ${media.desktop`
      position: initial;
      left: 0;
      bottom: initial;
      transform: initial;
      width: auto;
    `};
  }

  ${media.desktop`
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: auto;
  `};
`

const Title = styled.h1`
  text-align: center;
  font-size: 2em;
  line-height: 42px;
  width: 100%;
  text-transform: uppercase;
  margin: 0 0 2rem 0;
  padding: 2rem 0 0 0;

  ${media.desktop`
      font-size: 4.8em;
      line-height: 85px;
      margin: 0 0 3rem 0;
      text-align: left;
      width: 520px;
      padding: 0;
    `};
`

const SubTitle = styled.h2`
  text-align: center;
  font-weight: 400;

  ${media.desktop`
    font-size: 1.3em;
    line-height: 34px;
    margin: 0 0 3rem 0;
    text-align: left;
  `};
`

const ImageWrapper = styled(LazyLoad)`
  height: 100%;
  top: 0px;
  left: 0px;
  width: 100%;
  position: absolute;
`

const HomeBanner = ({ bannerContent }) => {
  const { isMobile } = useDeviceDetect()

  // const { lazyLoad } = useLazyLoading((target) => {
  //   target.setAttribute('src', target.getAttribute('data-src'))
  //   target.classList.add('loaded')
  // })

  // useEffect(() => {
  //   lazyLoad()
  // })

  if (
    !bannerContent.background_image[0].mobile ||
    !bannerContent.background_image[0].desktop
  )
    return null

  return (
    <Banner
      color={`${bannerContent.text_color}`}
      bgcolor={`${bannerContent.background_color}`}
      mobileGutter={typeof window !== 'undefined' ? window.innerHeight : 600}
    >
      {isMobile && (
        <ImageWrapper height={bannerContent.background_image[0].mobile.height}>
          <img
            width={bannerContent.background_image[0].mobile.width}
            height={bannerContent.background_image[0].mobile.height}
            loading='lazy'
            src={`${
              bannerContent.background_image[0].mobile.sizes?.optimized?.src ||
              bannerContent.background_image[0].mobile.sizes.medium_large
            }`}
            alt={`${bannerContent.background_image[0].mobile.alt}`}
          />
        </ImageWrapper>
      )}
      {!isMobile && (
        <ImageWrapper height={bannerContent.background_image[0].desktop.height}>
          <img
            width={bannerContent.background_image[0].desktop.width}
            height={bannerContent.background_image[0].desktop.height}
            loading='lazy'
            src={`${
              bannerContent.background_image[0].desktop?.optimized?.src ||
              bannerContent.background_image[0].desktop.url
            }`}
            alt={`${bannerContent.background_image[0].desktop.alt}`}
          />
        </ImageWrapper>
      )}
      <Container>
        <HeaderContent>
          <Title>{bannerContent.header_text}</Title>
          <SubTitle>{bannerContent.sub_text}</SubTitle>
          {bannerContent.cta.length > 0 && (
            <Cta
              url={`${bannerContent.cta[0].link}`}
              title={`${bannerContent.cta[0].action_text}`}
              bgcolor='#31c27c'
              padding='.8rem 2rem'
            />
          )}
        </HeaderContent>
      </Container>
    </Banner>
  )
}

export default HomeBanner
