import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import loadable from '@loadable/component'

import Layout from '../../components/common/layout'
import Seo from '../../components/common/seo'
import HomeBanner from './banner'
import storageService from '../../services/storageService'

const LogosArea = loadable(() => import('./logosArea'))
const TestimonialsArea = loadable(() => import('./testimonials'))
const ContentArea = loadable(() => import('./contentArea'))
const ContentAreaOne = loadable(() => import('./contentAreaOne'))
const ContentAreaTwo = loadable(() => import('./contentAreaTwo'))
const GamesArea = loadable(() => import('./gamesArea'))

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      homeApi {
        values {
          lang
          homeData {
            acf_fc_layout
            background_color
            header
            header_text
            header_title
            sub_text
            text_color
            testimonials {
              country
              description
              person_name
            }
            cta {
              action_text
              link
            }
            icon_list {
              description
              image {
                alt
                height
                width
                url
              }
            }
            image {
              mobile {
                alt
                height
                width
                url
              }
              desktop {
                alt
                width
                url
                height
                optimized {
                  src
                }
                sizes {
                  medium
                  medium_large
                  thumbnail
                  optimized {
                    src
                  }
                }
              }
            }
            list_of_games {
              category_section_name
              games {
                description
                desktopSlug
                enabled
                gameType
                mobileSlug
                providerCode
                providerName
                slug
                title
                vaultValue
                thumbnail {
                  alt
                  url
                  sizes {
                    medium
                  }
                }
                gametags {
                  gametag_daily_jackpot
                  gametag_hot
                  gametag_new
                  gametag_power_hour
                  gametag_power_week
                  gametag_vault_boost
                }
              }
            }
            logos_list {
              logo_text
              logo_image {
                alt
                height
                width
                url
              }
            }
            background_image {
              desktop {
                width
                height
                alt
                url
                optimized {
                  src
                }
              }
              mobile {
                alt
                height
                width
                url
                sizes {
                  medium
                  medium_large
                  optimized {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    homeApi: { values },
  } = data

  const intl = useIntl()

  const lobby = storageService.getValue('lobby')
  if (lobby) {
    navigate(lobby)
    return null
  }

  const dataExistForLocale = values.some((row) => row.lang === intl.locale)
  const dataExistForPage =
    dataExistForLocale &&
    values.filter((row) => row.lang === intl.locale)[0].homeData
  const home = dataExistForPage
    ? values.filter((row) => row.lang === intl.locale)[0].homeData
    : []

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: `seo.landingpage_title` })}
        description={intl.formatMessage({ id: `seo.landingpage_description` })}
      />

      {home.map((row, i) => {
        switch (row.acf_fc_layout) {
          case 'banner':
            return <HomeBanner key={i} bannerContent={row} />
          case 'logos_area':
            return <LogosArea key={i} logosContent={row} />
          case 'hot_games':
            return <GamesArea key={i} content={row} />
          case 'testimonials':
            return <TestimonialsArea key={i} testimonialsContent={row} />
          case 'content_area_1':
            return (
              <ContentArea
                key={i}
                color={row.text_color}
                bgcolor={row.background_color}
              >
                <ContentAreaOne contentData={row} />
              </ContentArea>
            )
          case 'content_area_2':
            return (
              <ContentArea
                key={i}
                color={row.text_color}
                bgcolor={row.background_color}
                padding='4.5rem 0 0 0'
              >
                <ContentAreaTwo contentData={row} />
              </ContentArea>
            )
          default:
            return <div />
        }
      })}
    </Layout>
  )
}

export default IndexPage
